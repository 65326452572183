.dots_custom_landing {
  position: fixed;
  top: calc(50% - 283px);
  left: calc(50% - 21px);
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
  padding: 0;
  width: 42px;
}

.dots_custom_landing li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  padding: 0 3px;
}

.dots_custom_landing li button {
  border: none;
  background: #bfbfbf;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  padding: 0;
}

@media (prefers-color-scheme: dark) {
  .dots_custom_landing li button {
    border: none;
    background: #7c7c7c;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    padding: 0;
  }
}

.dots_custom_landing li.slick-active button {
  background: #7c7c7c;
}

@media (prefers-color-scheme: dark) {
  .dots_custom_landing li.slick-active button {
    background: #bfbfbf;
  }
}
