/* slick.css */

.dots_custom_main {
  width: 100%;
  height: 8px;
  display: flex;
  vertical-align: middle;
  justify-content: left;
  margin: auto 0;
  padding: 0;
  margin-top: 10px;
  margin-left: 10px;
}

.dots_custom_main li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}

.dots_custom_main li button {
  border: none;
  background: #d9d9d9;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  padding: 0;
}

@media (prefers-color-scheme: dark) {
  .dots_custom_main li button {
    border: none;
    background: #7c7c7c;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    padding: 0;
  }
}

.dots_custom_main li.slick-active button {
  background-color: #7c7c7c;
}

@media (prefers-color-scheme: dark) {
  .dots_custom_main li.slick-active button {
    background-color: #d9d9d9;
  }
}
